//high level actions for async api calls
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";

//reducers/common.js
export const COMMON_CURRENT_ADMIN = "COMMON_CURRENT_ADMIN";
export const COMMON_REQ_START = "COMMON_REQ_START";
export const COMMON_REQ_SUCCESS = "COMMON_REQ_SUCCESS";
export const COMMON_REQ_FAILURE = "COMMON_REQ_FAILURE";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const NEW_ERRORS = "NEW_ERRORS";
export const NEW_FIELD_SPECIFIC_ERROR = "NEW_FIELD_SPECIFIC_ERROR";
export const CLEAR_FIELD_SPECIFIC_ERRORS = "CLEAR_FIELD_SPECIFIC_ERRORS";
export const PAGE_LOAD = "PAGE_LOAD";
export const TOGGLE_NAV_SHOWN = "TOGGLE_NAV_SHOWN";
export const COLLAPSE_NAV_DROPDOWNS = "COLLAPSE_NAV_DROPDOWNS";
export const COMMON_UPDATE_STATE = "COMMON_UPDATE_STATE";

//reducers/admin.js
export const ADMIN_REQ_START = "ADMIN_REQ_START";
export const ADMIN_REQ_SUCCESS = "ADMIN_REQ_SUCCESS";
export const ADMIN_REQ_FAILURE = "ADMIN_REQ_FAILURE";
export const ADMIN_LOAD = "ADMIN_LOAD";
export const ADMIN_LOAD_ALL = "ADMIN_LOAD_ALL";
export const ADMIN_LOAD_MULTI = "ADMIN_LOAD_MULTI";
export const ADMIN_EDIT_OPTIONS = "ADMIN_EDIT_OPTIONS";
export const ADMIN_EVENTS = "ADMIN_EVENTS";
export const ADMIN_PATIENT_COUNT = "ADMIN_PATIENT_COUNT";
export const ADMIN_UPDATE_STATE = "ADMIN_UPDATE_STATE";
export const ADMIN_UPDATE_STATE_OBJ = "ADMIN_UPDATE_STATE_OBJ";
export const ADMIN_LOAD_AVS = "ADMIN_LOAD_AVS";
export const ADMIN_LOAD_STAFF_CAPACITY = "ADMIN_LOAD_STAFF_CAPACITY";

//reducers/adminRole.js
export const ADMIN_ROLE_LOAD = "ADMIN_ROLE_LOAD";
export const ADMIN_ROLE_LOAD_LIST = "ADMIN_ROLE_LOAD_LIST";
export const ADMIN_ROLE_ADD_ADMIN = "ADMIN_ROLE_ADD_ADMIN";
export const ADMIN_ROLE_REMOVE_ADMIN = "ADMIN_ROLE_REMOVE_ADMIN";
export const LOAD_MEDICAL_ADMINS = "LOAD_MEDICAL_ADMINS";

//reduers/adminCalendar.js
export const ADMIN_CAL_UPDATE_STATE = "ADMIN_CAL_UPDATE_STATE";
export const ADMIN_CAL_INSERT_STAFF_AVAILABILITY =
  "ADMIN_CAL_INSERT_STAFF_AVAILABILITY";
export const ADMIN_CAL_INSERT_CAL_EVENT = "ADMIN_CAL_INSERT_CAL_EVENT";
export const ADMIN_CAL_UPDATE_DATE = "ADMIN_CAL_UPDATE_DATE";
export const ADMIN_CAL_LOAD_EVENT_DETAILS = "ADMIN_CAL_LOAD_EVENT_DETAILS";
export const ADMIN_CAL_REMOVE_OTHER_CAL_EVENT =
  "ADMIN_CAL_REMOVE_OTHER_CAL_EVENT";
export const ADMIN_CAL_REMOVE_STAFF_AVAILABILITY =
  "ADMIN_CAL_REMOVE_STAFF_AVAILABILITY";

//reducers/alcoholCheck.js
export const ALC_CHECK_REQ_START = "ALC_CHECK_REQ_START";
export const ALC_CHECK_REQ_SUCCESS = "ALC_CHECK_REQ_SUCCESS";
export const ALC_CHECK_REQ_FAILURE = "ALC_CHECK_REQ_FAILURE";
export const ALC_CHECK_LOAD = "ALC_CHECK_LOAD";
export const ALC_CHECK_RESPONSES_LOAD = "ALC_CHECK_RESPONSES_LOAD";
export const ALC_CHECK_QUESTIONS_LOAD = "ALC_CHECK_QUESTIONS_LOAD";

//reducers/asanaTask.js
export const ASANA_TASK_REQ_START = "ASANA_TASK_REQ_START";
export const ASANA_TASK_REQ_SUCCESS = "ASANA_TASK_REQ_SUCCESS";
export const ASANA_TASK_REQ_FAILURE = "ASANA_TASK_REQ_FAILURE";
export const ASANA_TASK_LOAD_ADMINS = "ASANA_TASK_LOAD_ADMINS";
export const ASANA_TASK_LOAD_FORM_OPTIONS = "ASANA_TASK_LOAD_FORM_OPTIONS";

//reducers/appointmentType.js
export const APPOINTMENT_TYPE_LOAD_ALL = "APPOINTMENT_TYPE_LOAD_ALL";

//reducers/availabilityTemplate.js
export const AVAIL_TEMPL_LOAD_MULTI = "AVAIL_TEMPL_LOAD_MULTI";
export const AVAIL_TEMPL_LOAD = "AVAIL_TEMPL_LOAD";
export const AVAIL_TEMPL_CREATE = "AVAIL_TEMPL_CREATE";
export const AVAIL_TEMPL_UPDATE_STATE = "AVAIL_TEMPL_UPDATE_STATE";
export const AVAIL_TEMPL_UPDATE = "AVAIL_TEMPL_UPDATE";

//reducers/billing.js
export const BILLING_START = "BILLING_START";
export const BILLING_SUCCESS = "BILLING_SUCCESS";
export const BILLING_FAILURE = "BILLING_FAILURE";
export const BILLING_PAGE_LOAD = "BILLING_PAGE_LOAD";
export const BILLING_SET_STATE = "BILLING_SET_STATE";
export const BILLING_LOAD_ACCOUNT = "BILLING_LOAD_ACCOUNT";
export const BILLING_SET_SHOWN_ACCOUNT = "BILLING_SET_SHOWN_ACCOUNT";
export const BILLING_LOAD_USER_ACCOUNTS = "BILLING_LOAD_USER_ACCOUNTS";
export const BILLING_LOAD_BANK_ACCOUNT = "BILLING_LOAD_BANK_ACCOUNT";
export const BILLING_LOAD_STRIPE_CUSTOMER = "BILLING_LOAD_STRIPE_CUSTOMER";
export const BILLING_LOAD_CARDS = "BILLING_LOAD_CARDS";
export const BILLING_LOAD_STRIPE_KEY = "BILLING_LOAD_STRIPE_KEY";
export const BILLING_LOAD_SUBSCRIPTIONS = "BILLING_LOAD_SUBSCRIPTIONS";
export const BILLING_LOAD_STRIPE_PAYMENTS = "BILLING_LOAD_STRIPE_PAYMENTS";
export const BILLING_LOAD_SCHEDULED_PAYMENTS =
  "BILLING_LOAD_SCHEDULED_PAYMENTS";
export const BILLING_LOAD_CASH_PAYMENTS = "BILLING_LOAD_CASH_PAYMENTS";
export const BILLING_LOAD_PATIENT_INVOICES = "BILLING_LOAD_PATIENT_INVOICES";
export const BILLING_LOAD_STRIPE_INVOICES = "BILLING_LOAD_STRIPE_INVOICES";
export const BILLING_LOAD_PAYMENT_STATUS = "BILLING_LOAD_PAYMENT_STATUS";
export const BILLING_LOAD_PAYMENT_PLAN = "BILLING_LOAD_PAYMENT_PLAN";
export const BILLING_LOAD_RECOMMENDED_BA_ID = "BILLING_LOAD_RECOMMENDED_BA_ID";
export const BILLING_LOAD_RECEIPT_INFO = "BILLING_LOAD_RECEIPT_INFO";

//reducers/calendar.js
export const CALENDAR_REQ_START = "CALENDAR_REQ_START";
export const CALENDAR_REQ_SUCCESS = "CALENDAR_REQ_SUCCESS";
export const CALENDAR_REQ_FAILURE = "CALENDAR_REQ_FAILURE";
export const CALENDAR_PAGE_LOAD = "CALENDAR_PAGE_LOAD";
export const CALENDAR_LOAD = "CALENDAR_LOAD";
export const CALENDAR_UPDATE_LOCAL_STATE = "CALENDAR_UPDATE_LOCAL_STATE";
export const CALENDAR_APPLY_NAVIGATION = "CALENDAR_APPLY_NAVIGATION";
export const CALENDAR_APPLY_FILTERS = "CALENDAR_APPLY_FILTERS";
export const CALENDAR_SELECT_EVENT = "CALENDAR_SELECT_EVENT";
export const CALENDAR_SELECTED_EVENT_DETAILS =
  "CALENDAR_SELECTED_EVENT_DETAILS";
export const CALENDAR_COMPOSE_EVENT = "CALENDAR_COMPOSE_EVENT";
export const CALENDAR_AVAILABILITY_VIEW = "CALENDAR_AVAILABILITY_VIEW";
export const CALENDAR_RESET = "CALENDAR_RESET";
export const CALENDAR_AVAILABILITIES_LOAD = "CALENDAR_AVAILABILITIES_LOAD";
export const CALENDAR_AVAILABILITIES_FILTER = "CALENDAR_AVAILABILITIES_FILTER";
export const CALENDAR_AVAIL_LOADING_TOGGLE = "CALENDAR_AVAIL_LOADING_TOGGLE";
export const CALENDAR_LOAD_EVENT = "CALENDAR_LOAD_EVENT";
export const CALENDAR_LOAD_AGENDA_EVENT = "CALENDAR_LOAD_AGENDA_EVENT";

//reducers/calEvents.js
export const CAL_EVENT_START = "CAL_EVENT_START";
export const CAL_EVENT_SUCCESS = "CAL_EVENT_SUCCESS";
export const CAL_EVENT_FAILURE = "CAL_EVENT_FAILURE";
export const CAL_EVENT_LOAD_USER_EVENTS = "CAL_EVENT_LOAD_USER_EVENTS";
export const CAL_EVENT_LOAD_EVENTS = "CAL_EVENT_LOAD_EVENTS";
export const CAL_EVENT_LOAD_EVENT = "CAL_EVENT_LOAD_EVENT";
export const CAL_EVENT_LOAD_BOOKING = "CAL_EVENT_LOAD_BOOKING";
export const CAL_EVENT_LOAD_USER_BOOKINGS = "CAL_EVENT_LOAD_USER_BOOKINGS";
export const CAL_EVENT_LOAD_HISTORY = "CAL_EVENT_LOAD_HISTORY";
export const CAL_EVENT_LOAD_RECURRING = "CAL_EVENT_LOAD_RECURRING";
export const CAL_EVENT_FOR_CLINIC_USER = "CAL_EVENT_FOR_CLINIC_USER";

//reducers/chatSession.js
export const CHAT_SESSION_START = "CHAT_SESSION_START";
export const CHAT_SESSION_SUCCESS = "CHAT_SESSION_SUCCESS";
export const CHAT_SESSION_FAILURE = "CHAT_SESSION_FAILURE";
export const CHAT_SESSION_SET_STATE = "CHAT_SESSION_SET_STATE";
export const CHAT_SESSION_LOAD_CHAT = "CHAT_SESSION_LOAD_CHAT";
export const CHAT_SESSION_LOAD_CHAT_MULTI = "CHAT_SESSION_LOAD_CHAT_MULTI";
export const CHAT_SESSION_LOAD_TOPIC_CHATS = "CHAT_SESSION_LOAD_TOPIC_CHATS";
export const CHAT_SESSION_LOAD_TOPIC_CHAT = "CHAT_SESSION_LOAD_TOPIC_CHAT";
export const CHAT_SESSION_LOAD_BUCKET_CHATS = "CHAT_SESSION_LOAD_BUCKET_CHATS";
export const CHAT_SESSION_LOAD_INTO_BUCKET = "CHAT_SESSION_LOAD_INTO_BUCKET";
export const CHAT_SESSION_LAST_MESSAGE = "CHAT_SESSION_LAST_MESSAGE";
export const CHAT_SESSION_LAST_MESSAGE_MULTI =
  "CHAT_SESSION_LAST_MESSAGE_MULTI";
export const CHAT_SESSION_UNREAD_COUNT = "CHAT_SESSION_UNREAD_COUNT";
export const CHAT_SESSION_UNRESOLVED_COUNT = "CHAT_SESSION_UNRESOLVED_COUNT";
export const CHAT_SESSION_MORE = "CHAT_SESSION_MORE";
export const CHAT_SESSION_MESSAGES = "CHAT_SESSION_MESSAGES";
export const CHAT_SESSION_CABLE_SUB = "CHAT_SESSION_CABLE_SUB";
export const CHAT_SESSION_CLEAR_CHAT_VARS = "CHAT_SESSION_CLEAR_CHAT_VARS";
export const CHAT_SESSION_LOAD_RESOLVED_CHATS =
  "CHAT_SESSION_LOAD_RESOLVED_CHATS";
export const CHAT_SESSION_SET_NATIONAL_CHATS =
  "CHAT_SESSION_SET_NATIONAL_CHATS";
export const CHAT_SESSION_UPDATE_NATIONAL_CHATS =
  "CHAT_SESSION_UPDATE_NATIONAL_CHATS";

//reducers/chatSubscriber.js
export const CHAT_SUBSCRIBER_LOAD = "CHAT_SUBSCRIBER_LOAD";
export const CHAT_SUBSCRIBER_LOAD_CLINIC = "CHAT_SUBSCRIBER_LOAD_CLINIC";
export const CHAT_SUBSCRIBER_REMOVE_ADMIN = "CHAT_SUBSCRIBER_REMOVE_ADMIN";

//reducers/clinics.js
export const CLINIC_REQ_START = "CLINIC_REQ_START";
export const CLINIC_REQ_SUCCESS = "CLINIC_REQ_SUCCESS";
export const CLINIC_REQ_FAILURE = "CLINIC_REQ_FAILURE";
export const CLINICS_LOAD = "CLINICS_LOAD";
export const CLINIC_LOAD = "CLINIC_LOAD";
export const CLINICS_PAGE_LOAD = "CLINICS_PAGE_LOAD";
export const CLINIC_ADMINS_LOAD = "CLINIC_ADMINS_LOAD";
export const CLINIC_UPDATE_STATE_LOCAL = "CLINIC_UPDATE_STATE_LOCAL";
export const CLINIC_LOAD_SEARCH_RESULTS = "CLINIC_LOAD_SEARCH_RESULTS";
export const CLINIC_APPOINTMENTS = "CLINIC_APPOINTMENTS";
export const CLINIC_PROVIDER_AVS = "CLINIC_PROVIDER_AVS";
export const CLINIC_INDUCTION_BLOCKS = "CLINIC_INDUCTION_BLOCKS";
export const CLINICS_LIST_LOAD = "CLINICS_LIST_LOAD";
export const CLINIC_LOAD_DTO = "CLINIC_LOAD_DTO";
export const CLINIC_LOAD_DTO_FILTERS = "CLINIC_LOAD_DTO_FILTERS";
export const CLINIC_LOAD_DTO_ONE = "CLINIC_LOAD_DTO_ONE";
export const CLINIC_REMOVE_DTO_ONE = "CLINIC_REMOVE_DTO_ONE";
export const CLINIC_OUTSTANDING_ITEMS = "CLINIC_OUTSTANDING_ITEMS";
export const CLINIC_PENDING_TIME_OFF = "CLINIC_PENDING_TIME_OFF";
export const CLINIC_DRUG_TESTING_TIMES = "CLINIC_DRUG_TESTING_TIMES";

//reducers/clinicFiles.js
export const CLINIC_FILE_LOAD = "CLINIC_FILE_LOAD";
export const CLINIC_FILE_REQ_START = "CLINIC_FILE_REQ_START";
export const CLINIC_FILE_REQ_SUCCESS = "CLINIC_FILE_REQ_SUCCESS";
export const CLINIC_FILE_REQ_FAILURE = "CLINIC_FILE_REQ_FAILURE";
export const CLINIC_FILE_REMOVE = "CLINIC_FILE_REMOVE";
export const CLINIC_FILE_LEGAL_DOCUMENTS = "CLINIC_FILE_LEGAL_DOCUMENTS";

//reducers/clinicPrescribers.js
export const CLINIC_PRESCRIBER_LOAD = "CLINIC_PRESCRIBER_LOAD";
export const CLINIC_PRESCRIBERS_BY_CLINIC_LOAD =
  "CLINIC_PRESCRIBERS_BY_CLINIC_LOAD";
export const CLINIC_PRESCRIBERS_BY_ADMIN_LOAD =
  "CLINIC_PRESCRIBERS_BY_ADMIN_LOAD";
export const CLINIC_PRESCRIBER_UPDATE_STATE = "CLINIC_PRESCRIBER_UPDATE_STATE";

//reducers/programCost.js
export const CLINIC_PROGRAM_REQ_START = "CLINIC_PROGRAM_REQ_START";
export const CLINIC_PROGRAM_REQ_SUCCESS = "CLINIC_PROGRAM_REQ_SUCCESS";
export const CLINIC_PROGRAM_REQ_FAILURE = "CLINIC_PROGRAM_REQ_FAILURE";
export const CLINIC_PROGRAM_LOAD_ALL = "CLINIC_PROGRAM_LOAD_ALL";
export const CLINIC_PROGRAM_LOAD_ONE = "CLINIC_PROGRAM_LOAD_ONE";
export const CLINIC_PROGRAM_DELETE_ONE = "CLINIC_PROGRAM_DELETE_ONE";
export const CLINIC_PROGRAM_LOAD_DETAILS = "CLINIC_PROGRAM_LOAD_DETAILS";
export const CLINIC_PROGRAM_LOAD_DETAILS_BY_PROGRAM =
  "CLINIC_PROGRAM_LOAD_DETAILS_BY_PROGRAM";
export const CLINIC_PROGRAM_LOAD_DETAILS_BY_CLINIC =
  "CLINIC_PROGRAM_LOAD_DETAILS_BY_CLINIC";
export const CLINIC_PROGRAM_LOAD_EVENTS = "CLINIC_PROGRAM_LOAD_EVENTS";
export const CLINIC_PROGRAM_LOAD_EVENTS_BY_PROGRAM =
  "CLINIC_PROGRAM_LOAD_EVENTS_BY_PROGRAM";
export const CLINIC_PROGRAM_LOAD_ICD_TENS = "CLINIC_PROGRAM_LOAD_ICD_TENS";
export const CLINIC_PROGRAM_LOAD_PROCEDURES = "CLINIC_PROGRAM_LOAD_PROCEDURES";

//reducers/clinicHoliday.js
export const CH_REQ_START = "CH_REQ_START";
export const CH_REQ_SUCCESS = "CH_REQ_SUCCESS";
export const CH_REQ_FAILURE = "CH_REQ_FAILURE";
export const CH_LOAD_ALL = "CH_LOAD_ALL";

//reducers/company.js
export const COMPANY_REQ_START = "COMPANY_REQ_START";
export const COMPANY_REQ_SUCCESS = "COMPANY_REQ_SUCCESS";
export const COMPANY_REQ_FAILURE = "COMPANY_REQ_FAILURE";
export const COMPANY_LOAD_ALL = "COMPANY_LOAD_ALL";

//reducers/curriculum.js
export const CURRICULUM_LOAD_TEMPLATES = "CURRICULUM_LOAD_TEMPLATES";

//reducers/discharge.js
export const DISCHARGE_REQ_START = "DISCHARGE_REQ_START";
export const DISCHARGE_REQ_SUCCESS = "DISCHARGE_REQ_SUCCESS";
export const DISCHARGE_REQ_FAILURE = "DISCHARGE_REQ_FAILURE";
export const DISCHARGE_LOAD = "DISCHARGE_LOAD";
export const DISCHARGE_LOAD_USER = "DISCHARGE_LOAD_USER";
export const DISCHARGE_LOAD_DETAILS = "DISCHARGE_LOAD_DETAILS";

//reducers/dischargeRequest.js
export const DISCHARGE_REQ_LOAD = "DISCHARGE_REQ_LOAD";
export const DISCHARGE_REQ_UPDATE_STATE = "DISCHARGE_REQ_UPDATE_STATE";
export const DISCHARGE_REQ_LOAD_IDS = "DISCHARGE_REQ_LOAD_IDS";

//reducers/drugTests.js
export const DRUG_TESTS_START = "DRUG_TESTS_START";
export const DRUG_TESTS_SUCCESS = "DRUG_TESTS_SUCCESS";
export const DRUG_TESTS_FAILURE = "DRUG_TESTS_FAILURE";
export const DRUG_TESTS_LOAD_TESTS = "DRUG_TESTS_LOAD_TESTS";
export const DRUG_TESTS_LOAD_TEST = "DRUG_TESTS_LOAD_TEST";
export const DRUG_TESTS_LOAD_USER = "DRUG_TESTS_LOAD_USER";
export const DRUG_TESTS_UPDATE_FORM = "DRUG_TESTS_UPDATE_FORM";
export const DRUG_TESTS_CLEAR_FORM = "DRUG_TESTS_CLEAR_FORM";
export const DRUG_TESTS_TOGGLE_FORM = "DRUG_TESTS_TOGGLE_FORM";
export const DRUG_TESTS_LOAD_MORE_USER = "DRUG_TESTS_LOAD_MORE_USER";
export const DRUG_TESTS_ADD_NEW = "DRUG_TESTS_ADD_NEW";
export const DRUG_TESTS_EDIT_ITEM = "DRUG_TESTS_EDIT_ITEM";
export const DRUG_TESTS_NEED_REVIEW_BY_CLINIC =
  "DRUG_TESTS_NEED_REVIEW_BY_CLINIC";
export const DRUG_TESTS_ADMIN_RESULT = "DRUG_TESTS_ADMIN_RESULT";
export const DRUG_TESTS_REMOVE_REVIEWED_ID = "DRUG_TESTS_REMOVE_REVIEWED_ID";
export const DRUG_TESTS_REVIEWED_BY_CLINIC = "DRUG_TESTS_REVIEWED_BY_CLINIC";

//reducers/drugTestOrders.js
export const DTO_START = "DTO_START";
export const DTO_SUCCESS = "DTO_SUCCESS";
export const DTO_FAILURE = "DTO_FAILURE";
export const DTO_UPDATE_LOCAL_STATE = "DTO_UPDATE_LOCAL_STATE";
export const DTO_LOAD_ORDERS = "DTO_LOAD_ORDERS";
export const DTO_LOAD_ONE = "DTO_LOAD_ONE";
export const DTO_REMOVE_ONE = "DTO_REMOVE_ONE";
export const DTO_SUGGESTED_ADDRESS = "DTO_SUGGESTED_ADDRESS";
export const DTO_SUBMITTED = "DTO_SUBMITTED";
export const DTO_FULFILLMENT_COST = "DTO_FULFILLMENT_COST";

//reducers/exercise.js
export const EXERCISE_START = "EXERCISE_START";
export const EXERCISE_SUCCESS = "EXERCISE_SUCCESS";
export const EXERCISE_FAILURE = "EXERCISE_FAILURE";
export const EXERCISE_UPDATE_LOCAL_STATE = "EXERCISE_UPDATE_LOCAL_STATE";
export const EXERCISE_LOAD = "EXERCISE_LOAD";
export const EXERCISE_LOAD_ALL = "EXERCISE_LOAD_ALL";
export const EXERCISE_APPLY_FILTER = "EXERCISE_APPLY_FILTER";
export const EXERCISE_UPDATE_SELECTED_FILTERS =
  "EXERCISE_UPDATE_SELECTED_FILTERS";
export const EXERCISE_UPDATE_SORT_BY = "EXERCISE_UPDATE_SORT_BY";
export const EXERCISE_SEARCH_RESULTS = "EXERCISE_SEARCH_RESULTS";
export const EXERCISE_LOAD_RESPONSE = "EXERCISE_LOAD_RESPONSE";
export const EXERCISE_LOAD_RESPONSE_INFO = "EXERCISE_LOAD_RESPONSE_INFO";

//reducers/forum.js
export const FORUM_CHANNEL_LOAD = "FORUM_CHANNEL_LOAD";
export const FORUM_UPDATE_STATE = "FORUM_UPDATE_STATE";
export const FORUM_CHANNEL_LOAD_MEMBERSHIP = "FORUM_CHANNEL_LOAD_MEMBERSHIP";
export const FORUM_CHANNEL_LOAD_MEMBERSHIPS_BY_CHANNEL =
  "FORUM_CHANNEL_LOAD_MEMBERSHIPS_BY_CHANNEL";
export const FORUM_CHANNEL_LOAD_FORUM_ADMIN = "FORUM_CHANNEL_LOAD_FORUM_ADMIN";
export const FORUM_CHANNEL_LOAD_FORUM_USER = "FORUM_CHANNEL_LOAD_FORUM_USER";

//reducers/globalSearch.js
export const UPDATE_QUERY = "UPDATE_QUERY";
export const GLOBAL_SEARCH_REQ_START = "GLOBAL_SEARCH_REQ_START";
export const GLOBAL_SEARCH_REQ_SUCCESS = "GLOBAL_SEARCH_REQ_SUCCESS";
export const GLOBAL_SEARCH_REQ_FAILURE = "GLOBAL_SEARCH_REQ_FAILURE";
export const UPDATE_SELECTED_ITEM = "UPDATE_SELECTED_ITEM";
export const GLOBAL_SEARCH_RESULTS_LOAD = "GLOBAL_SEARCH_RESULTS_LOAD";
export const GLOBAL_SEARCH_TOGGLE_RESULTS_SHOWN =
  "GLOBAL_SEARCH_TOGGLE_RESULTS_SHOWN";
export const GLOBAL_SEARCH_CAL_RESULTS = "GLOBAL_SEARCH_CAL_RESULTS";

//reducers/grant.js
export const GRANT_REQ_START = "GRANT_REQ_START";
export const GRANT_REQ_SUCCESS = "GRANT_REQ_SUCCESS";
export const GRANT_REQ_FAILURE = "GRANT_REQ_FAILURE";
export const GRANT_LOAD_ALL = "GRANT_LOAD_ALL";
export const GRANT_LOAD_USER = "GRANT_LOAD_USER";
export const GRANT_ELIGIBLE_CHECK = "GRANT_ELIGIBLE_CHECK";
export const GRANT_ELIGIBLE_RESULTS = "GRANT_ELIGIBLE_RESULTS";

//reducers/gtc.js
export const GTC_UPDATE_STATE = "GTC_UPDATE_STATE";

//reducers/healthInfo.js
export const HEALTH_INFO_REQ_START = "HEALTH_INFO_REQ_START";
export const HEALTH_INFO_REQ_SUCCESS = "HEALTH_INFO_REQ_SUCCESS";
export const HEALTH_INFO_REQ_FAILURE = "HEALTH_INFO_REQ_FAILURE";
export const HEALTH_INFO_LOAD = "HEALTH_INFO_LOAD";

//reducers/insuranceDetail.js
export const ID_REQ_START = "ID_REQ_START";
export const ID_REQ_SUCCESS = "ID_REQ_SUCCESS";
export const ID_REQ_FAILURE = "ID_REQ_FAILURE";
export const ID_LOAD = "ID_LOAD";
export const ID_UPDATE_STATE_LOCAL = "ID_UPDATE_STATE_LOCAL";
export const ID_CHECKING_COVERAGE = "ID_CHECKING_COVERAGE";
export const ID_COVERAGE_STATUS = "ID_COVERAGE_STATUS";
export const ID_PLAN_COSTS = "ID_PLAN_COSTS";
export const ID_USER_LOCATION = "ID_USER_LOCATION";
export const ID_CHECKING_LOCATION = "ID_CHECKING_LOCATION";
export const ID_CLEAR_DATA = "ID_CLEAR_DATA";
export const ID_INSURANCE_INFO_LOAD = "ID_INSURANCE_INFO_LOAD";
export const ID_CLEAR_COVERAGE_RESPONSE = "ID_CLEAR_COVERAGE_RESPONSE";
export const ID_USER_INSURANCE_DETAILS = "ID_USER_INSURANCE_DETAILS";
export const ID_REMOVE = "ID_REMOVE";
export const ID_CLEAR_INSURANCE_DETAILS = "ID_CLEAR_INSURANCE_DETAILS";
export const ID_CREDENTIALED_ADMINS_LOAD = "ID_CREDENTIALED_ADMINS_LOAD";
export const ID_LOAD_SUBSCRIBER = "ID_LOAD_SUBSCRIBER";
export const ID_LOAD_DETAIL_SUBSCRIBERS = "ID_LOAD_DETAIL_SUBSCRIBERS";

//reducers/insuranceClaim.js
export const INS_CLAIM_REQ_START = "INS_CLAIM_REQ_START";
export const INS_CLAIM_REQ_SUCCESS = "INS_CLAIM_REQ_SUCCESS";
export const INS_CLAIM_REQ_FAILURE = "INS_CLAIM_REQ_FAILURE";
export const INS_CLAIM_SELECTOR_VALUES_LOAD = "INS_CLAIM_SELECTOR_VALUES_LOAD";
export const INS_CLAIM_UPDATE_FILTER_LOCAL = "INS_CLAIM_UPDATE_FILTER_LOCAL";
export const INS_CLAIM_TOGGLE_LOADING = "INS_CLAIM_TOGGLE_LOADING";
export const INS_CLAIM_RESET_FILTERS = "INS_CLAIM_RESET_FILTERS";
export const INS_CLAIM_LOAD_APPOINTMENT_IDS = "INS_CLAIM_LOAD_APPOINTMENT_IDS";
export const INS_CLAIM_PAGE_LOAD = "INS_CLAIM_PAGE_LOAD";
export const INS_CLAIM_LOAD_PROCEDURES = "INS_CLAIM_LOAD_PROCEDURES";
export const INS_CLAIM_LOAD = "INS_CLAIM_LOAD";
export const INS_CLAIM_LOAD_MULTI = "INS_CLAIM_LOAD_MULTI";
export const INS_CLAIM_STATUS_LOAD = "INS_CLAIM_STATUS_LOAD";
export const INS_CLAIM_NOTE_BY_CLAIM = "INS_CLAIM_NOTE_BY_CLAIM";
export const INS_CLAIM_NOTE_BY_CLAIM_MULTI = "INS_CLAIM_NOTE_BY_CLAIM_MULTI";
export const INS_CLAIM_NOTE_LOAD = "INS_CLAIM_NOTE_LOAD";
export const INS_CLAIM_NOTE_LOAD_MULTI = "INS_CLAIM_NOTE_LOAD_MULTI";
export const INS_CLAIM_UPDATE_STATE = "INS_CLAIM_UPDATE_STATE";
export const INS_CLAIM_BY_USER_LOAD = "INS_CLAIM_BY_USER_LOAD";
export const INS_CLAIM_TOTAL_REVENUE = "INS_CLAIM_TOTAL_REVENUE";
export const INS_CLAIM_ITEM_LOAD = "INS_CLAIM_ITEM_LOAD";
export const INS_CLAIM_ITEM_LOAD_MULTI = "INS_CLAIM_ITEM_LOAD_MULTI";
export const INS_CLAIM_ITEMS_BY_CLAIM = "INS_CLAIM_ITEMS_BY_CLAIM";
export const INS_CLAIM_ITEMS_BY_CLAIM_MULTI = "INS_CLAIM_ITEMS_BY_CLAIM_MULTI";
export const INS_CLAIM_DESTROY = "INS_CLAIM_DESTROY";
export const INS_CLAIM_ITEM_DESTROY = "INS_CLAIM_ITEM_DESTROY";
export const INS_CLAIM_UPDATE_PAGE = "INS_CLAIM_UPDATE_PAGE";
export const INS_CLAIM_LOAD_EVENT = "INS_CLAIM_LOAD_EVENT";
export const INS_CLAIM_LOAD_EVENTS_BY_CLAIM = "INS_CLAIM_LOAD_EVENTS_BY_CLAIM";
export const INS_CLAIM_LOAD_RELATED_DATA = "INS_CLAIM_LOAD_RELATED_DATA";
export const INS_CLAIM_LOAD_BY_CAL_EVENT = "INS_CLAIM_LOAD_BY_CAL_EVENT";
export const INS_CLAIM_RESET_VIEW = "INS_CLAIM_RESET_VIEW";
export const INS_CLAIM_TAG_OPTIONS = "INS_CLAIM_TAG_OPTIONS";
export const INS_CLAIM_TAGS_BY_CLAIM_MULTI = "INS_CLAIM_TAGS_BY_CLAIM_MULTI";
export const INS_CLAIM_LOAD_BULK = "INS_CLAIM_LOAD_BULK";
export const INS_CLAIM_ITEM_LOAD_BULK = "INS_CLAIM_ITEM_LOAD_BULK";
export const INS_CLAIM_ITEMS_BY_CLAIM_BULK = "INS_CLAIM_ITEMS_BY_CLAIM_BULK";
export const INS_CLAIM_TAGS_BY_CLAIM_BULK = "INS_CLAIM_TAGS_BY_CLAIM_BULK";

//reducers/insuranceProcedure.js
export const INSURANCE_PROCEDURE_LOAD = "INSURANCE_PROCEDURE_LOAD";
export const INSURANCE_PROCEDURE_LOAD_MULTI = "INSURANCE_PROCEDURE_LOAD_MULTI";
export const INSURANCE_PROCEDURE_LOAD_PROVIDERS =
  "INSURANCE_PROCEDURE_LOAD_PROVIDERS";

//reducers/insuranceProvider.js
export const INS_PROV_LOAD = "INS_PROV_LOAD";
export const INS_PROV_LOAD_MULTI = "INS_PROV_LOAD_MULTI";
export const INS_PROV_STATE_COV_LOAD = "INS_PROV_STATE_COV_LOAD";
export const INS_PROV_STATE_COV_LOAD_MULTI = "INS_PROV_STATE_COV_LOAD_MULTI";
export const INS_PROV_STATE_COV_REMOVE = "INS_PROV_STATE_COV_REMOVE";
export const INS_PROV_STATE_COV_BY_PROV = "INS_PROV_STATE_COV_BY_PROV";
export const INS_PROV_STATE_COV_BY_PROV_MULTI =
  "INS_PROV_STATE_COV_BY_PROV_MULTI";
export const INS_PROV_CRED_PROV_LOAD = "INS_PROV_CRED_PROV_LOAD";
export const INS_PROV_CRED_PROV_BY_PROV_MULTI =
  "INS_PROV_CRED_PROV_BY_PROV_MULTI";
export const INS_PROV_LOAD_PROCEDURES = "INS_PROV_LOAD_PROCEDURES";
export const INS_PROV_REMOVE = "INS_PROV_REMOVE";
export const INS_PROV_FILTERS_UPDATE_FILTER_LOCAL =
  "INS_PROV_FILTERS_UPDATE_FILTER_LOCAL";
export const INS_PROV_FILTERS_UPDATE_STATE_LOCAL =
  "INS_PROV_FILTERS_UPDATE_STATE_LOCAL";
export const INS_PROV_FILTERS_CLEAR_FILTERS = "INS_PROV_FILTERS_CLEAR_FILTERS";
export const INS_PROV_FILTERS_SELECTOR_VALUES_LOAD =
  "INS_PROV_FILTERS_SELECTOR_VALUES_LOAD";
export const INS_PROV_FILTERS_REQ_START = "INS_PROV_FILTERS_REQ_START";
export const INS_PROV_FILTERS_REQ_SUCCESS = "INS_PROV_FILTERS_REQ_SUCCESS";
export const INS_PROV_FILTER_RESULTS_LOAD = "INS_PROV_FILTER_RESULTS_LOAD";

//reducers/insuranceProviderRate.js
export const INS_PROV_RATE_LOAD = "INS_PROV_RATE_LOAD";
export const INS_PROV_RATE_UPDATE_STATE = "INS_PROV_RATE_UPDATE_STATE";

//reducers/insuranceProviderKeyword.js
export const PREF_BILLING_TYPES_LOAD = "PREF_BILLING_TYPES_LOAD";

//reducers/medDoc.js
export const MED_DOC_REQ_START = "MED_DOC_REQ_START";
export const MED_DOC_REQ_SUCCESS = "MED_DOC_REQ_SUCCESS";
export const MED_DOC_REQ_FAILURE = "MED_DOC_REQ_FAILURE";
export const MED_DOC_LOAD = "MED_DOC_LOAD";

//reducers/note.js
export const NOTE_UPDATE_STATE = "NOTE_UPDATE_STATE";
export const NOTE_LOAD = "NOTE_LOAD";
export const NOTE_LOAD_COMBINED = "NOTE_LOAD_COMBINED";
export const NOTE_LOAD_NEW_COMBINED = "NOTE_LOAD_NEW_COMBINED";
export const NOTE_LOAD_UPDATED = "NOTE_LOAD_UPDATED";
export const NOTE_LOAD_ADDENDUM = "NOTE_LOAD_ADDENDUM";
export const NOTE_HAS_MORE = "NOTE_HAS_MORE";
export const NOTE_RESET_NEW_NOTE = "NOTE_RESET_NEW_NOTE";
export const NOTE_USER_DRAFTS = "NOTE_USER_DRAFTS";
export const NOTE_LOAD_PRINT = "NOTE_LOAD_PRINT";
export const NOTE_REMOVE_COMBINED = "NOTE_REMOVE_COMBINED";
export const ROLL_CALL_NOTE_LOAD_MEMBER_NOTE =
  "ROLL_CALL_NOTE_LOAD_MEMBER_NOTE";

//reducers/patientInvoice.js
export const PT_INV_SET_STATE = "PT_INV_SET_STATE";
export const PT_INV_LOAD = "PT_INV_LOAD";
export const PT_INV_LOAD_USER = "PT_INV_LOAD_USER";
export const PT_INV_LOAD_USER_ONE = "PT_INV_LOAD_USER_ONE";

//reducers/pendingItem.js
export const PI_LOAD = "PI_LOAD";
export const PI_ACTION_ITEMS = "PI_ACTION_ITEMS";
export const PI_COMPLETE_ACTION_ITEM = "PI_COMPLETE_ACTION_ITEM";
export const PI_UID_OPTIONS = "PI_UID_OPTIONS";
export const PI_RECOVERY_GROUP = "PI_RECOVERY_GROUP";

//reducers/platform.js
export const PLATFORM_REQ_START = "PLATFORM_REQ_START";
export const PLATFORM_REQ_SUCCESS = "PLATFORM_REQ_SUCCESS";
export const PLATFORM_REQ_FAILURE = "PLATFORM_REQ_FAILURE";
export const PLATFORM_UPDATE_STATE = "PLATFORM_UPDATE_STATE";
export const UPDATE_PLATFORM = "UPDATE_PLATFORM";
export const PLATFORM_LINKS = "PLATFORM_LINKS";
export const PLATFORM_HEADER = "PLATFORM_HEADER";
export const PLATFORM_VARS = "PLATFORM_VARS";
export const PLATFORM_NOTES = "PLATFORM_NOTES";
export const PLATFORM_FACE_SHEET = "PLATFORM_FACE_SHEET";
export const PLATFORM_CHART = "PLATFORM_CHART";
export const PLATFORM_ACTIVITY = "PLATFORM_ACTIVITY";
export const PLATFORM_PROGRESS = "PLATFORM_PROGRESS";
export const PLATFORM_APPOINTMENTS = "PLATFORM_APPOINTMENTS";
export const PLATFORM_ACCOUNT = "PLATFORM_ACCOUNT";
export const PLATFORM_PAYMENT_STATUS = "PLATFORM_PAYMENT_STATUS";
export const PLATFORM_RESET_FACE_SHEET = "PLATFORM_RESET_FACE_SHEET";
export const PLATFORM_NOTE_TOGGLE_DETAILS = "PLATFORM_NOTE_TOGGLE_DETAILS";
export const PLATFORM_NOTE_ADD_ITEM = "PLATFORM_NOTE_ADD_ITEM";
export const PLATFORM_NOTE_EDIT_ITEM = "PLATFORM_NOTE_EDIT_ITEM";
export const PLATFORM_NOTE_REMOVE_ITEM = "PLATFORM_NOTE_REMOVE_ITEM";
export const PLATFORM_NOTES_HAS_MORE = "PLATFORM_NOTES_HAS_MORE";
export const PLATFORM_NOTES_LOAD_MORE = "PLATFORM_NOTES_LOAD_MORE";
export const PLATFORM_NOTES_FILTER = "PLATFORM_NOTES_FILTER";
export const PLATFORM_UPDATE_CHECKLIST = "PLATFORM_UPDATE_CHECKLIST";
export const PLATFORM_NOTE_ADD_ADDENDUM = "PLATFORM_NOTE_ADD_ADDENDUM";
export const PLATFORM_GUT_CHECKS = "PLATFORM_GUT_CHECKS";
export const PLATFORM_ASSESSMENT_TRENDS = "PLATFORM_ASSESSMENT_TRENDS";

//reducers/prescriptionEncounter.js
export const PRES_ENC_LOAD_ENCOUNTERS = "PRES_ENC_LOAD_ENCOUNTERS";
export const PRES_ENC_LOAD_USER_ENCOUNTERS = "PRES_ENC_LOAD_USER_ENCOUNTERS";
export const PRES_ENC_LOAD_ORDERS = "PRES_ENC_LOAD_ORDERS";
export const PRES_ENC_LOAD_ENCOUNTER_ORDERS = "PRES_ENC_LOAD_ENCOUNTER_ORDERS";
export const PRES_ENC_LOAD_USER_ORDERS = "PRES_ENC_LOAD_USER_ORDERS";
export const PRES_ENC_ADD_USER_ENCOUNTER = "PRES_ENC_ADD_USER_ENCOUNTER";
export const PRES_ENC_UPDATE_STATE = "PRES_ENC_UPDATE_STATE";

//reducers/programCost.js
export const PROGRAM_COST_REQ_START = "PROGRAM_COST_REQ_START";
export const PROGRAM_COST_REQ_SUCCESS = "PROGRAM_COST_REQ_SUCCESS";
export const PROGRAM_COST_REQ_FAILURE = "PROGRAM_COST_REQ_FAILURE";
export const PROGRAM_COST_LOAD_ALL = "PROGRAM_COST_LOAD_ALL";
export const PROGRAM_COST_LOAD_ONE = "PROGRAM_COST_LOAD_ONE";
export const PROGRAM_COST_DELETE_ONE = "PROGRAM_COST_DELETE_ONE";

//reducers/providerAvailability.js
export const PROV_AVS_UPDATE_STATE = "PROV_AVS_UPDATE_STATE";
export const PROV_AVS_LOAD = "PROV_AVS_LOAD";

//reducers/providerNote.js
export const PROV_NOTE_REQ_START = "PROV_NOTE_REQ_START";
export const PROV_NOTE_REQ_SUCCESS = "PROV_NOTE_REQ_SUCCESS";
export const PROV_NOTE_REQ_FAILURE = "PROV_NOTE_REQ_FAILURE";
export const PROV_NOTE_LOAD = "PROV_NOTE_LOAD";
export const PROV_NOTE_LOAD_USER = "PROV_NOTE_LOAD_USER";
export const PROV_NOTE_UPDATE_STATE = "PROV_NOTE_UPDATE_STATE";
export const PROV_NOTE_LOAD_TEMPLATE_OPTIONS =
  "PROV_NOTE_LOAD_TEMPLATE_OPTIONS";
export const PROV_NOTE_LOAD_CATEGORIES_WITH_OPTIONS =
  "PROV_NOTE_LOAD_CATEGORIES_WITH_OPTIONS";
export const PROV_NOTE_RESET_NEW_NOTE = "PROV_NOTE_RESET_NEW_NOTE";
export const PROV_NOTE_LOAD_TAGS = "PROV_NOTE_LOAD_TAGS";
export const PROV_NOTE_LOAD_USER_TYPE = "PROV_NOTE_LOAD_USER_TYPE";
export const PROV_NOTE_TREATMENT_PLAN = "PROV_NOTE_TREATMENT_PLAN";
export const PROV_NOTE_FILL_TREATMENT_PLAN = "PROV_NOTE_FILL_TREATMENT_PLAN";
export const PROV_NOTE_LOAD_AFTER_SAVE = "PROV_NOTE_LOAD_AFTER_SAVE";
export const PROV_NOTE_LOAD_AVAILABLE_INFO = "PROV_NOTE_LOAD_AVAILABLE_INFO";

//reducers/providerReminders.js
export const PROVIDER_REMINDERS_START = "PROVIDER_REMINDERS_START";
export const PROVIDER_REMINDERS_SUCCESS = "PROVIDER_REMINDERS_SUCCESS";
export const PROVIDER_REMINDERS_FAILURE = "PROVIDER_REMINDERS_FAILURE";
export const PROVIDER_REMINDERS_LOAD_REMINDER =
  "PROVIDER_REMINDERS_LOAD_REMINDER";
export const PROVIDER_REMINDERS_LOAD_USER_REMINDERS =
  "PROVIDER_REMINDERS_LOAD_USER_REMINDERS";
export const PROVIDER_REMINDERS_UPDATE_NEW = "PROVIDER_REMINDERS_UPDATE_NEW";
export const PROVIDER_REMINDERS_CLEAR_NEW = "PROVIDER_REMINDERS_CLEAR_NEW";

//reducers/randomDrugTest.js
export const RDT_REQ_START = "RDT_REQ_START";
export const RDT_REQ_SUCCESS = "RDT_REQ_SUCCESS";
export const RDT_REQ_FAILURE = "RDT_REQ_FAILURE";
export const RDT_LOAD = "RDT_LOAD";
export const RDT_LOAD_USER = "RDT_LOAD_USER";
export const RDT_LOAD_FOR_CLINIC = "RDT_LOAD_FOR_CLINIC";
export const RDT_LOAD_OVERDUE_FOR_CLINIC = "RDT_LOAD_OVERDUE_FOR_CLINIC";
export const RDT_LOAD_NEXT_DUE = "RDT_LOAD_NEXT_DUE";
export const RDT_LOAD_MORE = "RDT_LOAD_MORE";

//reducers/recoveryGroup.js
export const REC_GROUP_REQ_START = "REC_GROUP_REQ_START";
export const REC_GROUP_REQ_SUCCESS = "REC_GROUP_REQ_SUCCESS";
export const REC_GROUP_REQ_FAILURE = "REC_GROUP_REQ_FAILURE";
export const REC_GROUP_LOAD_ALL = "REC_GROUP_LOAD_ALL";
export const REC_GROUP_LOAD_ONE = "REC_GROUP_LOAD_ONE";
export const REC_GROUP_SINGLE_LOAD = "REC_GROUP_SINGLE_LOAD";
export const REC_GROUP_LOAD_ONE_DICT = "REC_GROUP_LOAD_ONE_DICT";
export const REC_GROUP_REMOVE_ONE = "REC_GROUP_REMOVE_ONE";
export const REC_GROUP_LOAD_GROUP_ROLL_CALLS =
  "REC_GROUP_LOAD_GROUP_ROLL_CALLS";
export const REC_GROUP_LOAD_GROUP_ENROLLED_MEMBERS =
  "REC_GROUP_LOAD_GROUP_ENROLLED_MEMBERS";
export const REC_GROUP_LOAD_ATTENDEES = "REC_GROUP_LOAD_ATTENDEES";
export const REC_GROUP_LOAD_ATTENDEES_TODAY = "REC_GROUP_LOAD_ATTENDEES_TODAY";
export const REC_GROUP_LOAD_ROLL_CALL = "REC_GROUP_LOAD_ROLL_CALL";
export const REC_GROUP_GET_HOSTED_BY = "REC_GROUP_GET_HOSTED_BY";
export const REC_GROUP_APPLIED_FILTERS = "REC_GROUP_APPLIED_FILTERS";
export const SMA_FILTERS = "SMA_FILTERS";
export const REC_GROUP_SMA_LOAD = "REC_GROUP_SMA_LOAD";
export const REC_GROUP_UPDATE_STATE = "REC_GROUP_UPDATE_STATE";

export const REMINDER_REQ_START = "REMINDER_REQ_START";
export const REMINDER_REQ_SUCCESS = "REMINDER_REQ_SUCCESS";
export const REMINDER_REQ_FAILURE = "REMINDER_REQ_FAILURE";
export const REMINDER_LOAD_ALL = "REMINDER_LOAD_ALL";
export const REMINDER_LOAD_ONE = "REMINDER_LOAD_ONE";
export const REMINDER_UPDATE_ONE = "REMINDER_UPDATE_ONE";
export const REMINDER_DELETE_ONE = "REMINDER_DELETE_ONE";

//reducers/retentionEvent
export const RETENTION_FILTERS_UPDATE_FILTER_LOCAL =
  "RETENTION_FILTERS_UPDATE_FILTER_LOCAL";
export const RETENTION_FILTERS_REQ_START = "RETENTION_FILTERS_REQ_START";
export const RETENTION_FILTERS_REQ_SUCCESS = "RETENTION_FILTERS_REQ_SUCCESS";
export const RETENTION_FILTERS_REQ_FAILURE = "RETENTION_FILTERS_REQ_FAILURE";
export const RETENTION_FILTERS_TOGGLE_LOADING =
  "RETENTION_FILTERS_TOGGLE_LOADING";
export const RETENTION_UPDATE_LOCAL_STATE = "RETENTION_UPDATE_LOCAL_STATE";
export const RETENTION_LOAD_RESULT = "RETENTION_LOAD_RESULT";
export const RETENTION_VOLUME_LOAD_RESULT = "RETENTION_VOLUME_LOAD_RESULT";
export const RETENTION_SELECT_TILE = "RETENTION_SELECT_TILE";
export const RETENTION_EMIT_GRAPH_DATA = "RETENTION_EMIT_GRAPH_DATA";
export const RETENTION_LOAD_DISCHARGE = "RETENTION_LOAD_DISCHARGE";
export const RETENTION_LOAD_SCORE = "RETENTION_LOAD_SCORE";
export const RETENTION_LOAD_UPCOMING_APPOINTMENT =
  "RETENTION_LOAD_UPCOMING_APPOINTMENT";
export const RETENTION_LOAD_CELL_DATA = "RETENTION_LOAD_CELL_DATA";
export const RETENTION_SET_CELL_EVENTS = "RETENTION_SET_CELL_EVENTS";
export const RETENTION_TOGGLE_FILTERS_SHOWN = "RETENTION_TOGGLE_FILTERS_SHOWN";
export const RETENTION_UPDATED_SELECTED_VBAR =
  "RETENTION_UPDATED_SELECTED_VBAR";
export const RETENTION_INIT_PRIMARY_PROGRAM_FILTERS =
  "RETENTION_INIT_PRIMARY_PROGRAM_FILTERS";
export const RETENTION_TIME_ON_PLATFORM = "RETENTION_TIME_ON_PLATFORM";
export const RETENTION_MEMBER_ENGAGEMENT = "RETENTION_MEMBER_ENGAGEMENT";
export const RETENTION_ELATION_VISIT_NOTES = "RETENTION_ELATION_VISIT_NOTES";

//reducers/staffAvailability.js
export const STAFF_AVS_UPDATE_STATE = "STAFF_AVS_UPDATE_STATE";
export const STAFF_AVS_LOAD = "STAFF_AVS_LOAD";
export const STAFF_AVS_SET = "STAFF_AVS_SET";
export const STAFF_AVS_STORE = "STAFF_AVS_STORE";
export const STAFF_AVS_DB = "STAFF_AVS_DB";
export const STAFF_AVS_REMOVE_FROM_STORE = "STAFF_AVS_REMOVE_FROM_STORE";
export const UPDATE_LABEL = "UPDATE_LABEL";
export const WEEK_START_SET = "WEEK_START_SET";
export const STAFF_AVS_LOADING = "STAFF_AVS_LOADING";
export const STAFF_AVS_INSERT_UNSAVED = "STAFF_AVS_INSERT_UNSAVED";

//reducers/startTalking.js
export const START_TALKING_REQ_START = "START_TALKING_REQ_START";
export const START_TALKING_REQ_SUCCESS = "START_TALKING_REQ_SUCCESS";
export const START_TALKING_REQ_FAILURE = "START_TALKING_REQ_FAILURE";
export const START_TALKING_LOAD = "START_TALKING_LOAD";
export const START_TALKING_PREVIOUS_FORMS = "START_TALKING_PREVIOUS_FORMS";

//reducers/user.js
export const USER_LOAD = "USER_LOAD";
export const USER_LOAD_MULTI = "USER_LOAD_MULTI";
export const USER_PROFILE_LOAD = "USER_PROFILE_LOAD";
export const USER_PROFILE_LOAD_MULTI = "USER_PROFILE_LOAD_MULTI";
export const USER_NONCOMPLIANCE = "USER_NONCOMPLIANCE";
export const USER_REQ_START = "USER_REQ_START";
export const USER_REQ_SUCCESS = "USER_REQ_SUCCESS";
export const USER_REQ_FAILURE = "USER_REQ_FAILURE";
export const USER_UPDATE_STATE_LOCAL = "USER_UPDATE_STATE_LOCAL";
export const USER_NEW_ID = "USER_NEW_ID";
export const USER_PHOTO_IDS = "USER_PHOTO_IDS";
export const USER_PREFERENCE = "USER_PREFERENCE";
export const USER_QUICK_PROFILE = "USER_QUICK_PROFILE";
export const USER_LOAD_COUNSELOR = "USER_LOAD_COUNSELOR";
export const USER_LAB_STATUSES = "USER_LAB_STATUSES";
export const USER_TIMELINE_EVENTS = "USER_TIMELINE_EVENTS";
export const USER_WORKSPACE_LOAD = "USER_WORKSPACE_LOAD";
export const USER_LOAD_VERIFICATION_TOKEN = "USER_LOAD_VERIFICATION_TOKEN";
export const IS_MEMBER_AUD = "IS_MEMBER_AUD";

//reducers/userDiagnosis.js
export const USER_DIAGNOSIS_REQ_START = "USER_DIAGNOSIS_REQ_START";
export const USER_DIAGNOSIS_REQ_SUCCESS = "USER_DIAGNOSIS_REQ_SUCCESS";
export const USER_DIAGNOSIS_REQ_FAILURE = "USER_DIAGNOSIS_REQ_FAILURE";
export const USER_DIAGNOSIS_LOAD_USER = "USER_DIAGNOSIS_LOAD_USER";
export const USER_DIAGNOSIS_LOAD_PRIMARY = "USER_DIAGNOSIS_LOAD_PRIMARY";
export const USER_DIAGNOSIS_LOAD_PRIMARY_MULTI =
  "USER_DIAGNOSIS_LOAD_PRIMARY_MULTI";

//reducers/userFilters.js
export const USER_FILTERS_REQ_START = "USER_FILTERS_REQ_START";
export const USER_FILTERS_REQ_SUCCESS = "USER_FILTERS_REQ_SUCCESS";
export const USER_FILTERS_REQ_FAILURE = "USER_FILTERS_REQ_FAILURE";
export const USER_FILTERS_TOGGLE_LOADING = "USER_FILTERS_TOGGLE_LOADING";
export const USER_FILTERS_SEARCH_RESULTS_LOAD =
  "USER_FILTERS_SEARCH_RESULTS_LOAD";
export const USER_FILTERS_MORE_RESULTS_LOAD = "USER_FILTERS_MORE_RESULTS_LOAD";
export const USER_FILTERS_SELECTOR_VALUES_LOAD =
  "USER_FILTERS_SELECTOR_VALUES_LOAD";
export const USER_FILTERS_UPDATE_SUPPORT_GROUP_VALUES =
  "USER_FILTERS_UPDATE_SUPPORT_GROUP_VALUES";
export const USER_FILTERS_UPDATE_FILTER_LOCAL =
  "USER_FILTERS_UPDATE_FILTER_LOCAL";
export const USER_FILTERS_CLEAR_FILTERS = "USER_FILTERS_CLEAR_FILTERS";
export const USER_FILTERS_TOGGLE_ALL_USERS_SELECTED =
  "USER_FILTERS_TOGGLE_ALL_USERS_SELECTED";
export const USER_FILTERS_UPDATE_SELECTED_USERS_LOCAL =
  "USER_FILTERS_UPDATE_SELECTED_USERS_LOCAL";
export const USER_FILTERS_BEGIN_SEARCHING = "USER_FILTERS_BEGIN_SEARCHING";
export const USER_FILTERS_INIT_SEARCH = "USER_FILTERS_INIT_SEARCH";
export const USER_FILTERS_UPDATE_STATE_LOCAL =
  "USER_FILTERS_UPDATE_STATE_LOCAL";

//reducers/userMedication.js
export const USER_MED_REQ_START = "USER_MED_REQ_START";
export const USER_MED_REQ_SUCCESS = "USER_MED_REQ_SUCCESS";
export const USER_MED_REQ_FAILURE = "USER_MED_REQ_FAILURE";
export const USER_MED_LOAD = "USER_MED_LOAD";

//reducers/videoRoom.js
export const VR_REQ_START = "VR_REQ_START";
export const VR_REQ_SUCCESS = "VR_REQ_SUCCESS";
export const VR_REQ_FAILURE = "VR_REQ_FAILURE";
export const VR_LOAD = "VR_LOAD";
export const VR_TOKEN_LOAD = "VR_TOKEN_LOAD";
export const VR_LOAD_ROOM_DETAILS = "VR_LOAD_ROOM_DETAILS";
export const SET_LOCAL_VIDEO_REF = "SET_LOCAL_VIDEO_REF";
export const SET_REMOTE_VIDEO_REF = "SET_REMOTE_VIDEO_REF";
export const SET_REMOTE_AUDIO_REF = "SET_REMOTE_AUDIO_REF";
export const SET_TWILIO_ROOM_OBJ = "SET_TWILIO_ROOM_OBJ";
export const UPDATE_ROOM_STATUS = "UPDATE_ROOM_STATUS";
export const CLEAR_VIDEO_ROOM = "CLEAR_VIDEO_ROOM";
export const VIDEO_TOGGLE_VIDEO = "VIDEO_TOGGLE_VIDEO";
export const VIDEO_TOGGLE_AUDIO = "VIDEO_TOGGLE_AUDIO";
export const VIDEO_TOGGLE_REMOTE_AUDIO = "VIDEO_TOGGLE_REMOTE_AUDIO";
export const VIDEO_TOGGLE_REMOTE_VIDEO = "VIDEO_TOGGLE_REMOTE_VIDEO";
export const UPDATE_NAVIGATOR_SIZE = "UPDATE_NAVIGATOR_SIZE";
export const UPDATE_NAVIGATOR_VIEW = "UPDATE_NAVIGATOR_VIEW";
export const UPDATE_NAVIGATOR_BADGES = "UPDATE_NAVIGATOR_BADGES";
export const VR_SET_CHAT = "VR_SET_CHAT";
export const VIDEO_SET_URL_PARAMS = "VIDEO_SET_URL_PARAMS";
export const SET_LOCAL_TRACK_STREAM = "SET_LOCAL_TRACK_STREAM";
export const VIDEO_UPDATE_RINGING = "VIDEO_UPDATE_RINGING";
export const VIDEO_IS_PREVIOUS = "VIDEO_IS_PREVIOUS";
export const START_VIDEO_TIMER = "START_VIDEO_TIMER";
export const LOCAL_TRACKS_FINISHED_MOUNTING = "LOCAL_TRACKS_FINISHED_MOUNTING";

//reducers/videoCall.js
export const VC_INITIAL_REQUEST_FAILED = "VC_INITIAL_REQUEST_FAILED";
export const VC_TOKEN_LOAD = "VC_TOKEN_LOAD";
export const VC_SET_ERROR = "VC_SET_ERROR";
export const VC_SET_MEDIA_PERMISSIONS_ALLOWED =
  "VC_SET_MEDIA_PERMISSIONS_ALLOWED";
export const VC_SET_MEDIA_PERMISSIONS_BLOCKED =
  "VC_SET_MEDIA_PERMISSIONS_BLOCKED";
export const VC_UPDATE_STATE = "VC_UPDATE_STATE";
export const VC_SET_PARTICIPANT = "VC_SET_PARTICIPANT";
export const VC_REMOVE_ROOM = "VC_REMOVE_ROOM";
export const VC_SET_REMOTE_TRACK = "VC_SET_REMOTE_TRACK";
export const VC_LOAD_USER_CALLS = "VC_LOAD_USER_CALLS";
export const VC_LOAD_CURRENT = "VC_LOAD_CURRENT";
export const VC_LOAD_RECENT = "VC_LOAD_RECENT";
export const VC_REMOVE_RECENT = "VC_REMOVE_RECENT";

//reducers/videoRoomQueues.js
export const VRQ_LOAD_QUEUES = "VRQ_LOAD_QUEUES";
export const VRQ_LOAD_QUEUE = "VRQ_LOAD_QUEUE";
export const VRQ_UPDATE_STATE_LOCAL = "VRQ_UPDATE_STATE_LOCAL";
export const VRQ_LOAD_QUEUES_BY_CLINIC = "VRQ_LOAD_QUEUES_BY_CLINIC";
export const VRQ_USES_ZOOM = "VRQ_USES_ZOOM";

export const USER_ELATION_PATIENT_INFO_LOAD = "USER_ELATION_PATIENT_INFO_LOAD";
export const USER_ELATION_PATIENT_SYNC_DATA_LOAD =
  "USER_ELATION_PATIENT_SYNC_DATA_LOAD";
export const USER_ELATION_PATIENT_FAILED_SYNC_DATA_LOAD =
  "USER_ELATION_PATIENT_FAILED_SYNC_DATA_LOAD";

//reducers/zoomEvent.js
export const ZOOM_EVENTS_UPDATE_STATE = "ZOOM_EVENTS_UPDATE_STATE";
export const ZOOM_EVENTS_LOAD_EVENT = "ZOOM_EVENTS_LOAD_EVENT";
export const ZOOM_EVENTS_LOAD_RECOVERY_GROUP =
  "ZOOM_EVENTS_LOAD_RECOVERY_GROUP";
export const ZOOM_EVENTS_LOAD_HOST = "ZOOM_EVENTS_LOAD_HOST";
export const ZOOM_EVENTS_LOAD_HOSTS = "ZOOM_EVENTS_LOAD_HOSTS";
export const ZOOM_EVENTS_LOAD_PARTICIPANT = "ZOOM_EVENTS_LOAD_PARTICIPANT";
export const ZOOM_EVENTS_LOAD_PARTICIPANTS = "ZOOM_EVENTS_LOAD_PARTICIPANTS";
export const ZOOM_ADMIN_LOAD = "ZOOM_ADMIN_LOAD";
export const ZOOM_EVENTS_UPDATE_CAPACITY = " ZOOM_EVENTS_UPDATE_CAPACITY";
export const ZOOM_EVENTS_LOAD_GROUP_FOR_EVENT =
  "ZOOM_EVENTS_LOAD_GROUP_FOR_EVENT";
export const ZOOM_EVENTS_LOAD_EVENTS = "ZOOM_EVENTS_LOAD_EVENTS";

export const ZOOM_SUPPORT_GROUPS_LOAD_EVENT = "ZOOM_SUPPORT_GROUPS_LOAD_EVENT";
export const ZOOM_SUPPORT_GROUPS_LOAD_PARTICIPANT =
  "ZOOM_SUPPORT_GROUPS_LOAD_PARTICIPANT";
export const ZOOM_EVENTS_LOAD_QUEUE_PARTICIPANTS =
  "ZOOM_EVENTS_LOAD_QUEUE_PARTICIPANTS";

//reducers/priorAuthorization.js
export const PRIOR_AUTH_REQ_START = "PRIOR_AUTH_REQ_START";
export const PRIOR_AUTH_REQ_SUCCESS = "PRIOR_AUTH_REQ_SUCCESS";
export const PRIOR_AUTH_REQ_FAILURE = "PRIOR_AUTH_REQ_FAILURE";
export const PRIOR_AUTH_LOAD_AUTH = "PRIOR_AUTH_LOAD_AUTH";
export const PRIOR_AUTH_UNLOAD_AUTH = "PRIOR_AUTH_UNLOAD_AUTH";
